import React, { useState } from 'react';
import { DocusealForm } from '@docuseal/react';
import { FaPencilAlt } from 'react-icons/fa';
import '../App.css';

function GoogleAdsRetainer() {
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  // Function to send notification via GET request with query parameters
  const sendNotification = async (email, phone) => {
    try {
      // Data object includes email, phone, and a fixed retainer value
      const data = { email, phone, retainer: "Google Ads Retainer" };
      const queryParams = new URLSearchParams(data);
      await fetch(
        `https://hooks.zapier.com/hooks/catch/16292615/2crhzyp/?${queryParams.toString()}`,
        { method: 'GET' }
      );
      console.log('Notification sent via GET request');
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    // Ensure both email and phone are provided
    if (userEmail.trim() && userPhone.trim()) {
      await sendNotification(userEmail, userPhone);
      setEmailSubmitted(true);
    }
  };

  // Define custom CSS for DocusealForm elements
  const customCss = `
    .type-text-button,
    .upload-image-button,
    .clear-canvas-button,
    .set-current-date-button,
    .reupload-button,
    .base-button,
    #submit_form_button,
    body {
      background-color: #1a2b49 !important;
      border: 0;
      border-radius: 30px;
      color: #ffffff;
    }
    .template-title {
      color: #f9f7f5;
    }
  `;

  if (!emailSubmitted) {
    return (
      <div className="landing-page">
        <img src="/logo.png" alt="Logo" className="landing-logo" />
        <h1>Welcome to Our Retainer Portal</h1>
        <p>Please enter your email and phone number to start signing your retainer.</p>
        <form onSubmit={handleEmailSubmit} className="landing-form-stacked">
          <input
            type="email"
            placeholder="Enter your email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="landing-input"
          />
          <input
            type="tel"
            placeholder="Enter your phone number"
            value={userPhone}
            onChange={(e) => setUserPhone(e.target.value)}
            required
            className="landing-input"
          />
          <button type="submit" className="landing-button">
            <FaPencilAlt style={{ marginRight: '1rem' }} /> Go to Retainer
          </button>
          <div className="optin-container">
            <input type="checkbox" id="optin" required className="optin-checkbox" />
            <label htmlFor="optin">
              I hereby expressly consent to receive automated communications including calls, texts, emails, and/or prerecorded messages.
            </label>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div style={{ padding: "1rem", fontFamily: "Work Sans, serif" }}>
      <DocusealForm
        src="https://docuseal.com/d/uk9CMCZgYjB5hZ" // Form id for Google Ads Retainer
        email={userEmail}
        customCss={customCss}
        onComplete={(data) => {
          console.log("Document signing complete:", data);
        }}
      />
    </div>
  );
}

export default GoogleAdsRetainer;