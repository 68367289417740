import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SSDIRetainer from './pages/ssdi-retainer';
import DemandLaneRetainer from './pages/demand-lane-ssdi-retainer';
import FirstPartyPropertyRetainer from './pages/first-party-property-retainer';
import ManualFppRetainer from './pages/manual-fpp-retainer';
import Helene from './pages/helene';
import FppIntakeTeam from './pages/fpp-intake-team'
import GoogleAdsRetainer from './pages/google-ads-retainer'
import HurricaneRetainer25 from './pages/hurricane-retainer-25'
import HurricaneRetainer20 from './pages/hurricane-retainer-20'
import HurricaneRetainer from './pages/hurricane-retainer'


const BlankHome = () => {
  return null; // Renders nothing
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BlankHome />} />
        <Route path="/ssdi-retainer" element={<SSDIRetainer />} />
        <Route path="/first-party-property-retainer" element={<FirstPartyPropertyRetainer />} />
        <Route path="/manual-fpp-retainer" element={<ManualFppRetainer />} />
        <Route path="/hurricane-helene-retainer" element={<Helene />} />
        <Route path="/fpp-intake-team" element={<FppIntakeTeam />} />
        <Route path="/demand-lane-ssdi-retainer" element={<DemandLaneRetainer />} />
        <Route path="/google-ads-retainer" element={<GoogleAdsRetainer />} />
        <Route path="/hurricane-retainer" element={<HurricaneRetainer />} />
        <Route path="/hurricane-retainer-25" element={<HurricaneRetainer25 />} />
        <Route path="/hurricane-retainer-20" element={<HurricaneRetainer20 />} />
        
      </Routes>
    </Router>
  );
}

export default App;