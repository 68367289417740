import React, { useState } from 'react';
import { DocusealForm } from '@docuseal/react';
import { FaPencilAlt } from 'react-icons/fa';
import '../App.css';

function SSDIRetainer() {
  const [userEmail, setUserEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendNotification = async (email, phone) => {
    try {
      // Include a constant retainer field with value "SSDI Retainer"
      const data = { email, phone, retainer: "SSDI Retainer" };
      const queryParams = new URLSearchParams(data);
      await fetch(`https://hooks.zapier.com/hooks/catch/16292615/2crhzyp/?${queryParams.toString()}`, {
        method: 'GET'
      });
      console.log('Notification sent via GET request');
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (userEmail.trim() && phoneNumber.trim()) {
      // Send notification to Zapier using GET request with retainer field
      await sendNotification(userEmail, phoneNumber);
      // Proceed with the rest of your flow
      setFormSubmitted(true);
    }
  };

  // Custom CSS for Docuseal form
  const customCss = `
    .type-text-button,
    .upload-image-button,
    .clear-canvas-button,
    .set-current-date-button,
    .reupload-button,
    .base-button,
    #submit_form_button,
    body {
      background-color: #1a2b49 !important;
      border: 0;
      border-radius: 30px;
      color: #ffffff;
    }
    .template-title {
      color: #f9f7f5;
    }
  `;

  if (!formSubmitted) {
    return (
      <div className="landing-page">
        <img src="/logo.png" alt="Logo" className="landing-logo" />
        <h1>Welcome to Our Retainer Portal</h1>
        <p>Please enter your email and phone number to start signing your retainer.</p>
        <form onSubmit={handleFormSubmit} className="landing-form-stacked">
          <input
            type="email"
            placeholder="Enter your email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="landing-input"
          />
          <input
            type="tel"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            className="landing-input"
          />
          <div className="optin-container">
            <input type="checkbox" id="optin" required className="optin-checkbox" />
            <label htmlFor="optin">
              I hereby expressly consent to receive automated communications including calls, texts, emails, and/or prerecorded messages.
            </label>
          </div>
          <button type="submit" className="landing-button">
            <FaPencilAlt style={{ marginRight: '1rem' }} /> Go to Retainer
          </button>
        </form>
      </div>
    );
  }

  return (
    <div style={{ padding: '1rem', fontFamily: 'Work Sans, serif' }}>
      <DocusealForm
        src="https://docuseal.com/d/evN3tgQsYnYfee"
        email={userEmail}
        customCss={customCss}
        onComplete={(data) => {
          console.log('Document signing complete:', data);
        }}
      />
    </div>
  );
}

export default SSDIRetainer;